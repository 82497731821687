<template>
   <div class="bg-white border rounded-md p-10">
      <div class="flex justify-between py-5">
         <div class="">
            Anti-Phishing
         </div>
         <div>
            <div class="form-check form-switch">
               <input class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm pointer-events-none filter-none opacity-50" type="checkbox" role="switch" id="flexSwitchCheckDisabled" disabled>
               <label class="form-check-label inline-block text-gray-800 opacity-50" for="flexSwitchCheckDisabled"></label>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   
}
</script>